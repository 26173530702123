<template>
  <div>
    <b-overlay :show="loading">
      <template #overlay>
        <div
          class="text-center"
        >
          <feather-icon
            icon="LoaderIcon"
            size="40"
            variant="primary"
            class="rotate"
          />
          <p
            style="font-weight: bold; font-size: 1.2rem"
            class="text-primary"
          >
            POR FAVOR, ESPERE...
          </p>
        </div>
      </template>
      <b-tabs
        style="margin-top: -20px"
        content-class="pt-1"
        fill
      >
        <b-row>
          <b-col
            style="margin-top: -20px; margin-bottom: -20px"
            cols="12"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="10"
                >
                  <b-form-input
                    v-model="datosTicket"
                    style="margin-top: -9px; margin-bottom: -10px"
                    placeholder="Búsqueda por Número de Poste..."
                  />
                </b-col>
                <b-col
                  cols="2"
                >
                  <b-button
                    variant="primary"
                    style="margin-top: -12px; margin-bottom: -10px"
                    @click="loadTickets"
                  >
                    Refrescar Tickets
                    <feather-icon
                      size="20"
                      class="align-middle ml-25"
                      icon="RefreshCwIcon"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-tab
          title="PENDIENTES"
        >
          <b-row>
            <b-col
              v-for="(migracion, o) in migracionesAsignadas"
              :key="migracion.id"
              cols="12"
            >
              <CardMigraciones
                :id="migracion.id"
                :key="migracion.id"
                :migracion="migracion"
                :contador="o+1"
                :operacion="'N'"
                :tipo-lampara="tiposLamparas"
                :potencias="potencias"
                :trabajos-varios="trabajosVariosCargados"
                @actualizar-migraciones="actualizarMigraciones"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          title="EN CURSO"
        >
          <b-row>
            <b-col
              v-for="(migracion, o) in migracionesInstalacion"
              :key="migracion.id"
              cols="12"
            >
              <CardMigraciones
                :id="migracion.id"
                :key="migracion.id"
                :migracion="migracion"
                :contador="o+1"
                :operacion="'F'"
                :trabajos-varios="trabajosVariosCargados"
                @actualizar-migraciones="actualizarMigraciones"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>
<script>
import {
  BRow, BCol, BTabs, BTab, BOverlay, BCard, BFormInput, BButton,
} from 'bootstrap-vue'
import CardMigraciones from '@/components/migraciones/tecnicoMigraciones/CardMigraciones.vue'
import { getMigracion, getMigraciones } from '@/utils/migracionLuminarias'
import { getlistado } from '@/utils/catalogos'

export default {
  components: {
    CardMigraciones,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BButton,
    BOverlay,
    BFormInput,
  },
  data() {
    return {
      loading: false,
      datosTicket: '',
      tiposLamparas: [],
      potencias: [],
      migraciones: [],
      trabajosVariosCargados: [],
      trabajosCargados: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    migracionesAsignadas() {
      return this.filtrarTickets('ASIGNADA')
    },
    migracionesInstalacion() {
      return this.filtrarTickets('INSTALACION')
    },
  },
  async created() {
    await this.loadTickets()
    if (!this.trabajosCargados) {
      this.trabajosVariosCargados = await getlistado(30, 'Trabajos Levantamiento', false, this.usuario)
      this.trabajosCargados = true
    }
  },
  methods: {
    async actualizarMigraciones(idMigracion) {
      try {
        const migracionActualizada = await getMigracion(idMigracion)
        const index = this.migraciones.findIndex(migracion => migracion.id === idMigracion)
        if (index !== -1) {
          this.migraciones.splice(index, 1, migracionActualizada)
        }
      } catch (error) {
        console.error(`Error actualizando migración con id ${idMigracion}`, error)
      }
    },
    async loadTickets() {
      this.loading = true
      try {
        const filter = {
          where: {
            and: [
              { 'usuario.id': this.usuario.id },
              { estado: { neq: 'FINALIZADO' } },
              { estado: { neq: 'INGRESADA' } },
              { estado: { neq: 'MIGRADA' } },
            ],
          },
          order: ['fechaCreacion DESC'],
        }
        this.migraciones = await getMigraciones(filter)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
        this.datosTicket = ''
      }
    },
    filtrarTickets(estado) {
      const datosTicketLower = this.datosTicket.toLowerCase()
      let filtered = this.migraciones.filter(ticket => ticket.estado === estado)
      if (this.datosTicket !== '') {
        filtered = filtered.filter(migracion => migracion.numeroPoste.toString().toLowerCase().includes(datosTicketLower))
      }
      return filtered
    },
  },
}
</script>
<style scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1s linear infinite;
}
</style>
