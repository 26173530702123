<template>
  <div>
    <b-card
      :header="`TICKET NO. ${migracion.ticket}`"
      :title="`POSTE NO. ${migracion.levantamientoPunto.poste.numeroPoste}`"
      :footer="`${getDays()}`"
      class="text-center"
      footer-class="text-muted"
    >
      <b-row style="display: flex; justify-content: center">
        <div
          style="width: 45%"
          class="colsBorder"
        >
          <b-row>
            <b-col
              class="centradoCols"
              cols="12"
            >
              <span
                style="font-weight: bold"
                class="float-left text-primary"
              >
                DATOS LÁMPARA
              </span>
            </b-col>
            <b-col
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold">
                DISPOSITIVO:
              </span>
            </b-col>
            <b-col
              style="text-align: start"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.dispositivo.nombre }}
              </span>
            </b-col>
            <b-col
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold;">
                CLASE:
              </span>
            </b-col>
            <b-col
              style="text-align: start"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.clase.nombre }}
              </span>
            </b-col>
            <b-col
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold">
                TIPO:
              </span>
            </b-col>
            <b-col
              style="text-align: start"
              cols="3"
              sm="5"
            >
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.tipo && migracion.levantamientoPunto.lampara.tipo.nombre }}
              </span>
            </b-col>
            <b-col
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold">
                POTENCIA:
              </span>
            </b-col>
            <b-col
              style="text-align: center"
              cols="12"
            >
              <span
                style="font-size: small; color: #194F82; font-weight: bold"
              >
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.lamparaInstalar.nombre }}
              </span>
            </b-col>
            <b-col
              v-if="migracion.levantamientoPunto && migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.anguloInstalacion.nombre !== '0'"
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold">
                ÁNGULO A REALIZAR:
              </span>
            </b-col>
            <b-col
              v-if="migracion.levantamientoPunto && migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.anguloInstalacion.nombre !== '0'"
              style="text-align: start"
              cols="6"
            >
              <span
                style="font-size: small; color: #194F82; font-weight: bold"
              >
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.anguloInstalacion.nombre }}
              </span>
            </b-col>
            <b-col
              cols="12"
            >
              <span style="font-size: medium; font-weight: bold">
                TRABAJOS A REALIZAR:
              </span>
            </b-col>
            <b-col cols="12">
              <span :class="{'spans-trabajos': true, 'rojo': nombresLampara() === 'NO HAY TRABAJOS A REALIZAR'}">
                {{ nombresLampara() }}
              </span>
            </b-col>
          </b-row>
        </div>
        <div
          style="width: 45%"
          class="colsBorder"
        >
          <b-row>
            <b-col
              class="centradoCols"
              cols="12"
            >
              <span
                style="font-weight: bold"
                class="float-left text-primary"
              >
                DATOS BRAZO
              </span>
            </b-col>
            <b-col
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold">
                TIPO HERRAJE:
              </span>
            </b-col>
            <b-col
              style="text-align: start"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.tipoHerraje && migracion.levantamientoPunto.brazo.tipoHerraje.nombre }}
              </span>
            </b-col>
            <b-col
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold;">
                TAMAÑO:
              </span>
            </b-col>
            <b-col
              style="text-align: start"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.tamano && migracion.levantamientoPunto.brazo.tamano.nombre }}
              </span>
            </b-col>
            <b-col
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold;">
                TIPO:
              </span>
            </b-col>
            <b-col
              style="text-align: start"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.tipo && migracion.levantamientoPunto.brazo.tipo.nombre }}
              </span>
            </b-col>
            <b-col
              v-if="migracion.levantamientoPunto && migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.brazoInstalar && migracion.levantamientoPunto.brazo.brazoInstalar.nombre"
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold;">
                BRAZO A INSTALAR:
              </span>
            </b-col>
            <b-col
              v-if="migracion.levantamientoPunto && migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.brazoInstalar && migracion.levantamientoPunto.brazo.brazoInstalar.nombre"
              style="text-align: start"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.brazoInstalar && migracion.levantamientoPunto.brazo.brazoInstalar.nombre }}
              </span>
            </b-col>
            <b-col
              v-if="migracion.levantamientoPunto && migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.herrajeInstalar && migracion.levantamientoPunto.brazo.herrajeInstalar.nombre"
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold;">
                HERRAJE A INSTALAR:
              </span>
            </b-col>
            <b-col
              v-if="migracion.levantamientoPunto && migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.herrajeInstalar && migracion.levantamientoPunto.brazo.herrajeInstalar.nombre"
              style="text-align: start"
              cols="3"
              sm="5"
            >
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.herrajeInstalar && migracion.levantamientoPunto.brazo.herrajeInstalar.nombre }}
              </span>
            </b-col>
            <b-col
              cols="12"
            >
              <br>
              <span style="font-size: medium; font-weight: bold">
                TRABAJOS A REALIZAR:
              </span>
            </b-col>
            <b-col cols="12">
              <span :class="{'spans-trabajos': true, 'rojo': nombresBrazo() === 'NO HAY TRABAJOS A REALIZAR'}">
                {{ nombresBrazo() }}
              </span>
            </b-col>
          </b-row>
        </div>
        <div
          style="width: 45%"
          class="colsBorder"
        >
          <b-row>
            <b-col
              class="centradoCols"
              cols="12"
            >
              <span
                style="font-weight: bold"
                class="float-left text-primary"
              >
                DATOS POSTE
              </span>
            </b-col>
            <b-col
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold;">
                PROPIEDAD:
              </span>
            </b-col>
            <b-col
              style="text-align: start"
              cols="3"
              sm="5"
            >
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.poste && migracion.levantamientoPunto.poste.propiedad && migracion.levantamientoPunto.poste.propiedad.nombre }}
              </span>
            </b-col>
            <b-col
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold;">
                TAMAÑO:
              </span>
            </b-col>
            <b-col
              style="text-align: start"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.poste && migracion.levantamientoPunto.poste.tamano && migracion.levantamientoPunto.poste.tamano.nombre }}
              </span>
            </b-col>
            <b-col
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold;">
                TIPO:
              </span>
            </b-col>
            <b-col
              style="text-align: start"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.poste && migracion.levantamientoPunto.poste.tipoPoste && migracion.levantamientoPunto.poste.tipoPoste.nombre }}
              </span>
            </b-col>
            <b-col
              v-if="migracion.levantamientoPunto && migracion.levantamientoPunto.poste && migracion.levantamientoPunto.poste.posteInstalar && migracion.levantamientoPunto.poste.posteInstalar.nombre"
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold;">
                CAMBIO DE POSTE POR:
              </span>
            </b-col>
            <b-col
              v-if="migracion.levantamientoPunto && migracion.levantamientoPunto.poste && migracion.levantamientoPunto.poste.posteInstalar && migracion.levantamientoPunto.poste.posteInstalar.nombre"
              style="text-align: start"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.poste && migracion.levantamientoPunto.poste.posteInstalar && migracion.levantamientoPunto.poste.posteInstalar.nombre }}
              </span>
            </b-col>
            <b-col
              cols="12"
            >
              <span style="font-size: medium; font-weight: bold">
                TRABAJOS A REALIZAR:
              </span>
            </b-col>
            <b-col cols="12">
              <span :class="{'spans-trabajos': true, 'rojo': nombresPoste() === 'NO HAY TRABAJOS A REALIZAR'}">
                {{ nombresPoste() }}
              </span>
            </b-col>
          </b-row>
        </div>
        <div
          style="width: 45%"
          class="colsBorder"
        >
          <b-row>
            <b-col
              class="centradoCols"
              cols="12"
            >
              <span
                style="font-weight: bold"
                class="float-left text-primary"
              >
                DATOS CONEXIÓN
              </span>
            </b-col>
            <b-col
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <br>
              <span style="font-size: small; font-weight: bold;">
                TIPO:
              </span>
            </b-col>
            <b-col
              style="text-align: start"
              cols="3"
              sm="6"
            >
              <br>
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.conexion && migracion.levantamientoPunto.conexion.tipo && migracion.levantamientoPunto.conexion.tipo.nombre }}
              </span>
            </b-col>
            <b-col
              style="text-align: end"
              cols="3"
              sm="6"
            >
              <span style="font-size: small; font-weight: bold;">
                METROS A INSTALAR:
              </span>
            </b-col>
            <b-col
              style="text-align: start"
              cols="3"
              sm="5"
            >
              <span style="font-size: small; color: #194F82; font-weight: bold">
                {{ migracion.levantamientoPunto && migracion.levantamientoPunto.conexion && migracion.levantamientoPunto.conexion.cantidadTriplex }}m
              </span>
            </b-col>
            <b-col
              cols="12"
            >
              <br>
              <span style="font-size: medium; font-weight: bold">
                TRABAJOS A REALIZAR:
              </span>
            </b-col>
            <b-col cols="12">
              <span :class="{'spans-trabajos': true, 'rojo': nombresConexiones() === 'NO HAY TRABAJOS A REALIZAR'}">
                {{ nombresConexiones() }}
              </span>
            </b-col>
          </b-row>
        </div>
        <div
          style="width: 91%"
          class="colsBorder"
        >
          <b-row>
            <b-col
              class="centradoCols"
              cols="12"
            >
              <span
                style="font-weight: bold"
                class="float-left text-primary"
              >
                OTROS
              </span>
            </b-col>
            <b-col cols="6">
              <b-col
                cols="12"
              >
                <span style="font-size: medium; font-weight: bold">
                  OTROS TRABAJOS A REALIZAR:
                </span>
              </b-col>
              <b-col
                style="text-align: start"
                cols="12"
                sm="12"
              >
                <center>
                  <span :class="{'spans-trabajos': true, 'rojo': nombresOtros() === 'NO HAY TRABAJOS A REALIZAR'}">
                    {{ nombresOtros() }}
                  </span>
                </center>
              </b-col>
            </b-col>
            <b-col cols="6">
              <b-col
                class="centradoCols"
                cols="12"
              >
                <span style="font-size: small; font-weight: bold">
                  COMENTARIOS DEL TICKET
                </span>
              </b-col>
              <b-col cols="12">
                <span style="font-size: small; color: #194F82; font-weight: bold">
                  {{ migracion.levantamientoPunto ? migracion.levantamientoPunto.comentariosGenerales : '' }}
                </span>
              </b-col>
            </b-col>
          </b-row>
        </div>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-button
            block
            variant="primary"
            @click="abrirModalFoto"
          >
            Ver Foto
            <feather-icon icon="ImageIcon" />
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            block
            variant="info"
            @click="verUbicacionMaps(migracion)"
          >
            Ver Ubicación en Waze
            <feather-icon icon="MapPinIcon" />
          </b-button>
        </b-col>
      </b-row>
      <br>
      <div>
        <template>
          <b-table
            v-if="operacion !=='N'"
            responsive="sm"
            :fields="fields"
            :items="getTrabajos()"
          >
            <template #cell(item)="data">
              <span>TRABAJO DE {{ data.item.tipoTrabajo }} / {{ data.item.nombre }}</span>
            </template>
            <template #cell(seguimiento)="data">
              <modal-seguimiento-trabajos
                :migracion-trabajo="data.item"
                :migracion="migracion"
                @actualizar="actualizar"
                @actualizar-migración="actualizarMigracion"
              />
            </template>
          </b-table>
        </template>
      </div>
      <modal-seguimiento-migraciones
        v-if="operacion === 'N'"
        :migracion="migracion"
        :operacion="'N'"
        :trabajos="trabajosVarios"
        @actualizar="actualizar()"
        @actualizar-cuerpo-migracion="actualizarMigracion"
      />
      <modal-seguimiento-migraciones
        v-if="operacion === 'F'"
        :migracion="migracion"
        :operacion="'F'"
        :trabajos="trabajosVarios"
        @actualizar="actualizar"
        @actualizar-cuerpo-migracion="actualizarMigracion"
      />
    </b-card>
    <div>
      <b-modal
        :id="migracion.id"
        :key="migracion.id"
        v-model="verModalFoto"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        ok-only
        ok-title="Aceptar"
        modal-class="modal-primary"
        centered
        title="Fotografía"
        style="max-height: 200px"
      >
        <center>
          <b-img
            fluid
            alt="Imagen Panorámica"
            :src="usuario.company.enabledReport ? migracion.levantamientoPunto.imagenPanoramica.url : ''"
          />
        </center>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BTable, BButton, BImg,
} from 'bootstrap-vue'
import { diferenceBetweenToday, diferenceBetweenTodayNoAbs } from '@/utils/fechas'
import ModalSeguimientoMigraciones from '@/components/migraciones/tecnicoMigraciones/ModalSeguimientoMigraciones.vue'

import ModalSeguimientoTrabajos from '@/components/migraciones/tecnicoMigraciones/modalSeguimientoTrabajos.vue'

export default {
  components: {
    ModalSeguimientoTrabajos,
    ModalSeguimientoMigraciones,
    BCard,
    BRow,
    BCol,
    BButton,
    BImg,
    BTable,
  },
  props: {
    migracion: {
      type: Object,
      required: false,
    },
    operacion: {
      type: String,
      required: false,
    },
    contador: {
      type: Number,
      required: false,
    },
    trabajosVarios: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      verModalFoto: false,
      nameTable: 'unidades_varias',
      fields: ['item', 'seguimiento'],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    nombresLampara() {
      let datosDeTrabajos = this.migracion.levantamientoPunto.lampara.trabajos
      // Verificar si existe this.migracion.trabajos.lampara antes de acceder a sus propiedades
      if (this.migracion.trabajos && this.migracion.trabajos.lampara) {
        const cambioLampara = this.migracion.trabajos.lampara.trabajos.find(trabajo => trabajo.nombre === 'CAMBIO DE LÁMPARA')
        if (cambioLampara) {
          // Si existe el trabajo 'CAMBIO DE LÁMPARA', lo añadimos a la lista de trabajos
          datosDeTrabajos = [...datosDeTrabajos, cambioLampara]
        }
      }
      return this.mapearNombres(datosDeTrabajos, 'lampara')
    },
    nombresBrazo() {
      const datosDeTrabajos = this.migracion.levantamientoPunto.brazo.trabajos
      return this.mapearNombres(datosDeTrabajos, 'brazo')
    },
    nombresPoste() {
      const datosDeTrabajos = this.migracion.levantamientoPunto.poste.trabajos
      return this.mapearNombres(datosDeTrabajos, 'poste')
    },
    nombresConexiones() {
      const datosDeTrabajos = this.migracion.levantamientoPunto.conexion.trabajos
      return this.mapearNombres(datosDeTrabajos, 'conexiones')
    },
    nombresOtros() {
      const datosDeTrabajos = this.migracion.levantamientoPunto.otrosTrabajos
      return this.mapearNombres(datosDeTrabajos, 'otros')
    },
    mapearNombres(datosDeTrabajos, tipo) {
      // eslint-disable-next-line array-callback-return,consistent-return
      const nombresDeTrabajos = datosDeTrabajos.map(trabajo => {
        if (trabajo && trabajo.nombre) {
          if (trabajo.nombre === 'ÁNGULO') {
            // Obtener el nombre del ángulo de instalación dependiendo del tipo
            let datosDelAngulo = ''
            if (tipo === 'lampara') {
              datosDelAngulo = this.migracion.levantamientoPunto.lampara.anguloInstalacion.nombre
            } else if (tipo === 'brazo') {
              datosDelAngulo = this.migracion.levantamientoPunto.brazo.anguloInstalacion.nombre
            } else if (tipo === 'poste') {
              datosDelAngulo = this.migracion.levantamientoPunto.poste.anguloInstalacion.nombre
            } else if (tipo === 'conexiones') {
              datosDelAngulo = this.migracion.levantamientoPunto.conexiones.anguloInstalacion.nombre
            } else if (tipo === 'otros') {
              datosDelAngulo = this.migracion.levantamientoPunto.otros.anguloInstalacion.nombre
            }
            return `${trabajo.nombre} (${datosDelAngulo})`
          } if (trabajo.nombre === 'INSTALACIÓN DE TRIPLEX') {
            // Agregar la cantidad de triplex si el nombre es "INSTALACIÓN DE TRIPLEX"
            let cantidadTriplex = ''
            if (tipo === 'conexiones' && this.migracion.levantamientoPunto.conexion.cantidadTriplex) {
              cantidadTriplex = this.migracion.levantamientoPunto.conexion.cantidadTriplex
            }
            return `${trabajo.nombre} (${cantidadTriplex}m)`
          }
          return trabajo.nombre
        }
      })
      return nombresDeTrabajos.filter(nombre => nombre).join(' | ') || 'NO HAY TRABAJOS A REALIZAR'
    },
    getTrabajos() {
      let trabajos = []
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const tipoTrabajo in this.migracion.trabajos) {
        trabajos = trabajos.concat(this.migracion.trabajos[tipoTrabajo].trabajos)
      }
      return trabajos
    },
    abrirModalFoto() {
      this.verModalFoto = true
    },
    verUbicacionMaps(migracion) {
      if (migracion.levantamientoPunto.imagenPanoramica) {
        const registro = migracion.levantamientoPunto.imagenPanoramica.localization

        if (registro.lat === '' || registro.lng === '') return

        // Esto abrirá la aplicación de waze
        const url = `waze://?ll=${registro.lat},${registro.lng}&navigate=yes`

        window.location.href = url
      }
    },
    actualizar() {
      this.$emit('actualizar')
    },
    actualizarMigracion(idMigracion) {
      this.$emit('actualizar-migraciones', idMigracion)
    },
    getDays() {
      const diff = diferenceBetweenTodayNoAbs(this.migracion.fechaCreacion)
      const realDiff = diferenceBetweenToday(this.migracion.fechaCreacion)
      if (diff > 0) return `Dentro de ${realDiff} día(s)`
      if (diff < 0) return `${realDiff} día(s) de atraso`
      return 'Hoy'
    },
  },
}
</script>
<style scoped>
.colsBorder {
  border: 2px solid #194F82;
  border-radius: 12px;
  margin: 5px;
  padding: 10px
}
.centradoCols {
  justify-content: center;
  display: flex;
}
.spans-trabajos {
  font-size: medium;
  color: #008f39;
  font-weight: bold;
  text-transform: uppercase;
}
.spans-trabajos.rojo {
  color: red;
}
</style>
