<template>
  <div>
    <b-overlay :show="loading">
      <div class="contenedor-qr">
        <h5 class="error">
          {{ error }}
        </h5>
        <qrcode-stream
          :capture="'shoot'"
          :track="datosEscaneados"
          @detect="onDetect"
          @init="onInit"
        />
        <div
          v-if="qrContent"
          class="qr-content"
        >
          Contenido del QR: {{ qrContent }}
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import { BOverlay } from 'bootstrap-vue'
import { mensajeError } from '@/utils/mensajes'

export default {
  components: {
    QrcodeStream,
    BOverlay,
  },
  props: {
    origen: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      error: '',
      qrContent: '',
    }
  },
  methods: {
    async onDetect(promise) {
      try {
        if (this.origen === 'mantenimientoBodega') {
          const { content } = await promise
          const decodedContent = atob(content)

          const jsonData = JSON.parse(decodedContent)

          this.$emit('datos', jsonData)
        } else if (this.origen === 'DESPACHOS') {
          const { content } = await promise
          const decodedContent = atob(content)

          const jsonData = JSON.parse(decodedContent)

          this.$emit('datos', jsonData)
        } else {
          const { content } = await promise
          const removerPrimerCodigo = /P\/N:\s*([^\s]+)/
          const removerSegundoCodigo = /(?:S\/N:|SN)\s*([^\s]+)/i

          const matchPrimerCodigo = content.match(removerPrimerCodigo)
          const matchSegundoCodigo = content.match(removerSegundoCodigo)
          const datosEscaneados = {
            primerCodigo: matchPrimerCodigo ? matchPrimerCodigo[1] : null,
            segundoCodigo: matchSegundoCodigo ? matchSegundoCodigo[1] : null,
          }
          this.$emit('datos', datosEscaneados)
        }
      } catch (error) {
        console.error('Error al escanear el código QR:', error)
        mensajeError('Código QR no válido, intenta nuevamente!')
      }
    },
    async onInit(promise) {
      this.loading = true
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: Necesitas otorgarle permiso de acceso a la cámara'
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: No hay cámara en este dispositivo'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: Requieres una ruta segura (HTTPS, localhost)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: La cámara ya está siendo usada?'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: La cámara instalada no es adecuada'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Lector de QR no es compatible con este navegador'
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: El acceso a la cámara solo está permitido en una ruta segura. Utilice HTTPS o localhost en lugar de HTTP.'
        } else {
          this.error = `ERROR: Error de la cámara (${error.name})`
        }
      } finally {
        this.loading = false
      }
    },
    datosEscaneados(detectedCodes, ctx) {
      try {
        if (this.origen === 'mantenimientoBodega') {
          // eslint-disable-next-line no-restricted-syntax
          for (const detectedCode of detectedCodes) {
            const { boundingBox, rawValue } = detectedCode

            // Decodificar el valor base64
            const decodedValue = atob(rawValue)
            const jsonData = JSON.parse(decodedValue)
            const codigoLampara = jsonData.codigo
            const serieLampara = jsonData.numeroPoste

            const centerX = boundingBox.x + boundingBox.width / 2
            const centerY = boundingBox.y + boundingBox.height / 2

            // eslint-disable-next-line no-mixed-operators
            const fontSize = Math.max(12, 50 * boundingBox.width / ctx.canvas.width)

            // eslint-disable-next-line no-param-reassign
            ctx.font = `bold ${fontSize}px sans-serif`
            // eslint-disable-next-line no-param-reassign
            ctx.textAlign = 'center'
            // eslint-disable-next-line no-param-reassign
            ctx.lineWidth = 5
            // eslint-disable-next-line no-param-reassign
            ctx.strokeStyle = '#006394'
            // eslint-disable-next-line no-param-reassign
            ctx.strokeText(`SERIE LUMINARIA: ${codigoLampara} POSTE: ${serieLampara}`, centerX, centerY)
            // eslint-disable-next-line no-param-reassign
            ctx.fillStyle = '#fbdc00'
            // eslint-disable-next-line no-param-reassign
            ctx.fillText(`SERIE LUMINARIA: ${codigoLampara} POSTE: ${serieLampara}`, centerX, centerY)
          }
        } else if (this.origen === 'DESPACHOS') {
          // eslint-disable-next-line no-restricted-syntax
          for (const detectedCode of detectedCodes) {
            const { boundingBox, rawValue } = detectedCode
            const decodedValue = atob(rawValue)

            // Convierte la cadena decodificada en un objeto JSON
            const jsonData = JSON.parse(decodedValue)
            const serieLampara = jsonData.serie

            const centerX = boundingBox.x + boundingBox.width / 2
            const centerY = boundingBox.y + boundingBox.height / 2

            // eslint-disable-next-line no-mixed-operators
            const fontSize = Math.max(12, 50 * boundingBox.width / ctx.canvas.width)

            // eslint-disable-next-line no-param-reassign
            ctx.font = `bold ${fontSize}px sans-serif`
            // eslint-disable-next-line no-param-reassign
            ctx.textAlign = 'center'
            // eslint-disable-next-line no-param-reassign
            ctx.lineWidth = 5
            // eslint-disable-next-line no-param-reassign
            ctx.strokeStyle = '#006394'
            // eslint-disable-next-line no-param-reassign
            ctx.strokeText(`SERIE LUMINARIA: ${serieLampara}`, centerX, centerY)
            // eslint-disable-next-line no-param-reassign
            ctx.fillStyle = '#fbdc00'
            // eslint-disable-next-line no-param-reassign
            ctx.fillText(`SERIE LUMINARIA: ${serieLampara}`, centerX, centerY)
          }
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const detectedCode of detectedCodes) {
            const { boundingBox, rawValue } = detectedCode

            const centerX = boundingBox.x + boundingBox.width / 2
            const centerY = boundingBox.y + boundingBox.height / 2

            // eslint-disable-next-line no-mixed-operators
            const fontSize = Math.max(12, 50 * boundingBox.width / ctx.canvas.width)

            // eslint-disable-next-line no-param-reassign
            ctx.font = `bold ${fontSize}px sans-serif`
            // eslint-disable-next-line no-param-reassign
            ctx.textAlign = 'center'
            // eslint-disable-next-line no-param-reassign
            ctx.lineWidth = 5
            // eslint-disable-next-line no-param-reassign
            ctx.strokeStyle = '#006394'
            // eslint-disable-next-line no-param-reassign
            ctx.strokeText(rawValue, centerX, centerY)
            // eslint-disable-next-line no-param-reassign
            ctx.fillStyle = '#fbdc00'
            // eslint-disable-next-line no-param-reassign
            ctx.fillText(rawValue, centerX, centerY)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
<style>
.contenedor-qr {
  max-height: 100%;
  max-width: 100%;
  margin-bottom: 10px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
