<template>
  <div>
    <b-button
      v-if="debeMostrarBoton()"
      :variant="obtenerVariantBoton()"
      @click="abrirModalTrabajos"
    >
      {{ obtenerTextoBoton() }}
    </b-button>
    <h5
      v-else
      style="color:#C12E35;"
    >
      Completado
    </h5>
    <b-modal
      v-model="verModalTrabajos"
      no-close-on-backdrop
      hide-footer
      :title="`TRABAJO DE ${migracionTrabajo.tipoTrabajo}`"
      modal-class="modal-primary"
      ok-title="Guardar seguimiento"
      @hide="limpiarCampos"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-overlay
            :show="loading"
          >
            <b-row>
              <b-col cols="12">
                <center>
                  <h3 class="text-primary text-uppercase">
                    {{ migracionTrabajo.nombre }}
                  </h3>
                </center>
              </b-col>
            </b-row>
            <b-row v-if="migracionTrabajo && migracionTrabajo.nombre === 'CAMBIO DE LÁMPARA'">
              <b-col
                cols="12"
              >
                <b-form-group>
                  <h5
                    style="margin-top: 8px"
                    class="text-primary"
                  >
                    LÁMPARA A INSTALAR:
                  </h5>
                  <b-form-input
                    :value="migracion.levantamientoPunto && migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.lamparaInstalar.nombre"
                    class="text-center"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="6"
              >
                <b-form-group>
                  <h5
                    style="margin-top: 8px"
                    class="text-primary"
                  >
                    DISPOSITIVO:
                  </h5>
                  <b-form-input
                    :value="migracion.levantamientoPunto && migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.dispositivo.nombre"
                    class="text-center"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="6"
              >
                <b-form-group>
                  <h5
                    style="margin-top: 8px"
                    class="text-primary"
                  >
                    TIPO:
                  </h5>
                  <b-form-input
                    :value="migracion.levantamientoPunto && migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.tipo && migracion.levantamientoPunto.lampara.tipo.nombre"
                    class="text-center"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="migracionTrabajo && migracionTrabajo.nombre === 'ÁNGULO'">
              <b-col
                cols="12"
              >
                <b-form-group>
                  <h5
                    style="margin-top: 8px"
                    class="text-primary"
                  >
                    ÁNGULO A REALIZAR:
                  </h5>
                  <b-form-input
                    :value="migracion.levantamientoPunto && migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.anguloInstalacion.nombre"
                    class="text-center"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="migracionTrabajo && migracionTrabajo.nombre === 'CAMBIO DE BRAZO'">
              <b-col
                cols="12"
              >
                <b-form-group>
                  <h5
                    style="margin-top: 8px"
                    class="text-primary"
                  >
                    BRAZO A INSTALAR:
                  </h5>
                  <b-form-input
                    :value="migracion.levantamientoPunto && migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.brazoInstalar && migracion.levantamientoPunto.brazo.brazoInstalar.nombre"
                    class="text-center"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="migracionTrabajo && migracionTrabajo.nombre === 'CAMBIO HERRAJE'">
              <b-col
                cols="12"
              >
                <b-form-group>
                  <h5
                    style="margin-top: 8px"
                    class="text-primary"
                  >
                    Herraje a Instalar:
                  </h5>
                  <b-form-input
                    :value="migracion.levantamientoPunto && migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.herrajeInstalar && migracion.levantamientoPunto.brazo.herrajeInstalar.nombre"
                    class="text-center"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="migracionTrabajo && migracionTrabajo.nombre === 'INSTALACIÓN DE TRIPLEX'">
              <b-col
                cols="12"
              >
                <b-form-group>
                  <h5 class="text-primary">
                    METROS DE CABLE A INSTALAR:
                  </h5>
                  <b-form-input
                    :value="migracion.levantamientoPunto && migracion.levantamientoPunto.conexion && migracion.levantamientoPunto.conexion.cantidadTriplex"
                    class="text-center"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="migracionTrabajo && migracionTrabajo.nombre === 'REQUIERE CAMBIO'"
                cols="12"
              >
                <b-form-group>
                  <h5 class="text-primary">
                    POSTE A INSTALAR:
                  </h5>
                  <b-form-input
                    :value="migracion.levantamientoPunto && migracion.levantamientoPunto.poste && migracion.levantamientoPunto.poste.posteInstalar && migracion.levantamientoPunto.poste.posteInstalar.nombre"
                    class="text-center"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- INICIO DE SEGUIMIENTO -->
            <b-row v-if="migracionTrabajo.seguimiento.length === 0">
              <b-col
                cols="12"
              >
                <h6>Foto de Inicio del Trabajo</h6>
              </b-col>
              <b-col
                cols="12"
              >
                <center>
                  <imagen
                    :key="'inicio'"
                    :leyenda="'inicio'"
                    @cargar-file="imagenInicio"
                  />
                </center>
              </b-col>
            </b-row>
            <!-- FIN DE SEGUIMIENTO -->
            <b-row v-else-if="migracionTrabajo.seguimiento.length === 1">
              <b-col
                cols="12"
              >
                <h6>Foto de Finalización del Trabajo</h6>
              </b-col>
              <b-col
                cols="12"
              >
                <center>
                  <imagen
                    :key="'fin'"
                    :leyenda="'fin'"
                    @cargar-file="imagenFin"
                  />
                </center>
              </b-col>
            </b-row>
            <!-- FOTO DE STICKER EN LÁMPARA (ESTO SOLO SE MUESTRA EN EL TRABAJO DE CAMBIO DE LÁMPARA) -->
            <b-row v-else-if="migracionTrabajo.seguimiento.length === 2 && migracionTrabajo.nombre === 'CAMBIO DE LÁMPARA'">
              <b-col
                cols="12"
              >
                <h6>Foto de Lámpara con Sticker</h6>
              </b-col>
              <b-col
                cols="12"
              >
                <center>
                  <imagen
                    :key="'sticker'"
                    :leyenda="'sticker'"
                    @cargar-file="imagenSticker"
                  />
                </center>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <h5>Comentarios</h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Comentarios"
                    rules="required"
                  >
                    <b-form-textarea
                      id="comentarios"
                      v-model="comentarios"
                      :state="errors.length > 0 ? false:null"
                      rows="2"
                      no-resize
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <center>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2 mr-1 align-self-center"
                    @click="validationForm"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Guardar Seguimiento</span>
                  </b-button>
                </center>
              </b-col>
            </b-row>
          </b-overlay>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol, BButton, BOverlay, BFormTextarea, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { required } from '@core/utils/validations/validations'
import { saveImagen } from '@/utils/imagenes'
import { mensajeError, mensajeInformativo, notificacionInformativa } from '@/utils/mensajes'
import Ripple from 'vue-ripple-directive'
import { updateCreateMigracion } from '@/utils/migracionLuminarias'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    Imagen,
    BButton,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    migracion: {
      required: true,
    },
    migracionTrabajo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      loading: false,
      fotoInicio: '',
      fotoSticker: '',
      fotoFin: '',
      comentarios: null,
      verModalTrabajos: false,
      localization: null,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.guardarSeguimietnoTrabajo()
        }
      })
    },
    async guardarSeguimietnoTrabajo() {
      try {
        this.loading = true
        if (this.migracionTrabajo.seguimiento.length === 0 && this.fotoInicio === '') {
          mensajeError('Debe ingresar una fotografía del inicio del trabajo')
          return
        } if (this.migracionTrabajo.seguimiento.length === 1 && this.fotoFin === '') {
          mensajeError('Debe ingresar una fotografía de la finalización del trabajo')
          return
        } if (this.migracionTrabajo.seguimiento.length === 2 && this.migracionTrabajo.nombre === 'CAMBIO DE LÁMPARA' && this.fotoSticker === '') {
          mensajeError('Debe ingresar una fotografía de la lámpara con su sticker')
          return
        }
        const body = this.migracionTrabajo

        this.usuario = {
          id: this.usuario.id,
          nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
          email: this.usuario.email,
        }

        if (!body.seguimiento || body.seguimiento.length === 0) {
          body.estado = 'FIN'
        }
        if (body.nombre === 'CAMBIO DE LÁMPARA' && body.seguimiento.length === 1) {
          body.estado = 'STICKER'
        }

        const urlBucket = `migracion-luminarias/${this.migracion.id}`
        const isMobile = window.screen.width <= 760

        let imagen = null
        if (this.migracionTrabajo.seguimiento.length === 0) {
          imagen = await saveImagen(urlBucket, this.localization, this.fotoInicio, 'Inicio de Seguimiento', this.comentarios, this.usuario, isMobile)
        } else if (this.migracionTrabajo.seguimiento.length === 1) {
          imagen = await saveImagen(urlBucket, this.localization, this.fotoFin, 'Fin de Seguimiento ', this.comentarios, this.usuario, isMobile)
        } else if (this.migracionTrabajo.seguimiento.length === 2 && this.migracionTrabajo.nombre === 'CAMBIO DE LÁMPARA') {
          imagen = await saveImagen(urlBucket, this.localization, this.fotoSticker, 'Lámpara con Sticker', this.comentarios, this.usuario, isMobile)
        }

        if (imagen) {
          body.seguimiento.push(imagen)
        }
        mensajeInformativo('Guardado', 'Seguimiento guardado correctamente')
        await updateCreateMigracion(this.migracion, 1)
        notificacionInformativa('Sustitución Actualizada', 'Sustitución Actualizada Correctamente!')
        this.verModalTrabajos = false
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
        this.$emit('actualizar-migración', this.migracion.id)
      }
    },
    abrirModalTrabajos() {
      this.verModalTrabajos = true
    },
    obtenerVariantBoton() {
      if (this.migracionTrabajo.seguimiento.length === 0) {
        return 'primary'
      } if (this.migracionTrabajo.seguimiento.length === 1) {
        return 'danger'
      } if (this.migracionTrabajo.seguimiento.length === 2 && this.migracionTrabajo.nombre === 'CAMBIO DE LÁMPARA') {
        return 'warning'
      }
      return 'primary'
    },
    obtenerTextoBoton() {
      if (this.migracionTrabajo.seguimiento.length === 0) {
        return 'Ingresar Seguimiento'
      } if (this.migracionTrabajo.seguimiento.length === 1) {
        return 'Finalizar Seguimiento'
      } if (this.migracionTrabajo.seguimiento.length === 2 && this.migracionTrabajo.nombre === 'CAMBIO DE LÁMPARA') {
        return 'Sticker Lámpara'
      }
      return 'Ingresar Seguimiento'
    },
    debeMostrarBoton() {
      if (this.migracionTrabajo.seguimiento.length === 0) {
        return true
      } if (this.migracionTrabajo.seguimiento.length === 1) {
        return true
      } if (this.migracionTrabajo.seguimiento.length === 2 && this.migracionTrabajo.nombre === 'CAMBIO DE LÁMPARA') {
        return true
      }
      return false
    },
    limpiarCampos() {
      this.fotoInicio = ''
      this.fotoFin = ''
      this.fotoSticker = ''
      this.comentarios = ''
    },
    imagenInicio(file) {
      this.fotoInicio = file
    },
    imagenFin(file) {
      this.fotoFin = file
    },
    imagenSticker(file) {
      this.fotoSticker = file
    },
  },
}
</script>
