<template>
  <div>
    <b-button
      v-if="todosSeguimientosCompletos()"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.modal-detalle
      variant="relief-info"
      block
      @click="abrirModalSeguimiento"
    >
      {{ operacion === "N" ? "Iniciar Inspección" : "Finalizar Inspección" }}
    </b-button>
    <b-modal
      :id="migracion.id"
      :key="migracion.id"
      v-model="verModalSeguimiento"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-footer
      modal-class="modal-primary"
      centered
      title="Seguimiento"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-overlay :show="loading">
            <b-card-text>
              <h4 align="center">
                {{
                  operacion === "N"
                    ? "Iniciar"
                    : operacion === "F"
                      ? "Finalizar"
                      : "Seguimiento"
                }}
                Ticket
              </h4>
              <br>
              <!-- SI INICIA-->
              <b-row v-if="operacion === 'N'">
                <b-col cols="12">
                  <h6>Foto de Inicio</h6>
                </b-col>
                <b-col cols="12">
                  <center>
                    <imagen
                      :key="'inicio'"
                      :leyenda="'inicio'"
                      @cargar-file="imagenInicio"
                    />
                  </center>
                </b-col>
              </b-row>
              <!-- SI FINALIZA -->
              <b-row v-if="operacion === 'F'">
                <b-col cols="12">
                  <h6>Foto de Finalización</h6>
                </b-col>
                <b-col cols="12">
                  <center>
                    <imagen
                      :key="'fin'"
                      :leyenda="'fin'"
                      @cargar-file="imagenFin"
                    />
                  </center>
                </b-col>
              </b-row>
              <b-col cols="12">
                <h5>Comentarios</h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Comentarios"
                    rules="required"
                  >
                    <b-form-textarea
                      id="comentarios"
                      v-model="comentarios"
                      :state="errors.length > 0 ? false : null"
                      rows="2"
                      no-resize
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="align-content-center"
              >
                <center>
                  <b-button
                    v-if="operacion === 'N'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2 mr-1 align-self-center"
                    @click="validationForm"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Guardar Seguimiento</span>
                  </b-button>
                  <b-button
                    v-if="operacion === 'F'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2 mr-1 align-self-center"
                    @click="validationFormFin"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Guardar Seguimiento</span>
                  </b-button>
                </center>
                <br>
              </b-col>
            </b-card-text>
          </b-overlay>
        </b-form>
      </validation-observer>
    </b-modal>
    <div>
      <b-modal
        v-model="verModalLuminaria"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        hide-footer
        size="lg"
        modal-class="modal-primary"
        centered
        title="Validación de Luminaria"
        @hide="limpiarIntervalo"
      >
        <b-overlay :show="loading">
          <b-row>
            <b-col cols="12">
              <b-button
                block
                variant="primary"
                @click="mostrarModalBusqueda('LUMINARIAS')"
              >
                Buscar Luminaria Manualmente
              </b-button>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="5">
              <center>
                <span
                  class="text-primary"
                  style="
                    font-weight: bold;
                    margin-right: 8px;
                    margin-top: 100px;
                  "
                >
                  Escaneo por cámara
                </span>
              </center>
            </b-col>
            <b-col cols="2">
              <center>
                <b-form-checkbox
                  v-model="tipoEscaner"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </center>
            </b-col>
            <b-col cols="5">
              <center>
                <span
                  class="text-primary"
                  style="
                    font-weight: bold;
                    margin-right: 8px;
                    margin-top: 100px;
                  "
                >
                  Escaneo por lector
                </span>
              </center>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="!tipoEscaner"
              style="display: flex; justify-content: center"
              cols="12"
            >
              <h2 class="text-primary text-uppercase">
                ¡Escanea el QR de la Luminaria!
              </h2>
            </b-col>
          </b-row>
          <div style="max-height: 60%">
            <b-row v-if="!tipoEscaner">
              <b-col cols="12">
                <escaner-mobile @datos="escaneoMobileLuminaria" />
              </b-col>
              <b-col
                v-if="datosEscaneadosMobile"
                cols="12"
              >
                <h6 class="text-primary">
                  SERIE:
                </h6>
                <b-form-group>
                  <b-form-input
                    :value="datosEscaneadosMobile && datosEscaneadosMobile.segundoCodigo"
                    class="text-center"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="datosEscaneadosMobile"
                cols="12"
              >
                <center>
                  <b-button
                    variant="primary"
                    class="mt-2 mr-1 align-self-center"
                    @click="extraerCodigos('luminaria')"
                  >
                    <span class="align-middle">Validar Luminaria</span>
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                    />
                  </b-button>
                </center>
              </b-col>
            </b-row>
            <b-row
              v-else
              style="display: flex; justify-content: center"
            >
              <b-row>
                <b-col
                  v-if="!textoEscaneado"
                  style="display: flex; justify-content: center"
                  cols="12"
                >
                  <h2 class="text-primary text-uppercase">
                    ¡Escanea el QR de la Luminaria!
                  </h2>
                </b-col>
                <b-col
                  v-if="!textoEscaneado"
                  style="display: flex; justify-content: center"
                  cols="12"
                >
                  <b-img
                    :src="require('/public/qr-code-icon.gif')"
                    alt="Escaner QR"
                    style="
                      width: 180px;
                      height: 180px;
                      border-radius: 15px;
                      padding: 5px;
                      box-shadow: 10px 12px 25px rgba(0, 0, 0, 0.5);
                    "
                  />
                </b-col>
                <b-col
                  v-if="textoEscaneado"
                  style="display: flex; justify-content: center"
                  cols="12"
                >
                  <h1 class="text-primary text-uppercase">
                    ¡Los datos coinciden!
                  </h1>
                </b-col>
                <b-col
                  v-if="textoEscaneado"
                  style="display: flex; justify-content: center"
                  cols="12"
                >
                  <b-img
                    :src="require('/public/check-green.gif')"
                    alt="Escaner QR"
                    style="
                      width: 180px;
                      height: 180px;
                      border-radius: 15px;
                      padding: 5px;
                      box-shadow: 10px 12px 25px rgba(0, 0, 0, 0.5);
                    "
                  />
                </b-col>
              </b-row>
              <b-col cols="12">
                <input
                  ref="inputQR"
                  v-model="textoEscaneado"
                  class="qr-textarea"
                  type="text"
                  style="position: absolute; top: -9999px"
                  @change="extraerCodigos('luminaria')"
                >
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-modal>
    </div>
    <div>
      <b-modal
        v-model="verModalNema"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        hide-footer
        size="lg"
        modal-class="modal-primary"
        centered
        title="Validación de Nema"
        @hide="limpiarIntervalo"
      >
        <b-overlay :show="loading">
          <b-row>
            <b-col cols="12">
              <b-button
                block
                variant="primary"
                @click="mostrarModalBusqueda('NEMAS')"
              >
                Buscar Nema Manualmente
              </b-button>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="5">
              <center>
                <span
                  class="text-primary"
                  style="
                    font-weight: bold;
                    margin-right: 8px;
                    margin-top: 100px;
                  "
                >
                  Escaneo por cámara
                </span>
              </center>
            </b-col>
            <b-col cols="2">
              <center>
                <b-form-checkbox
                  v-model="tipoEscaner"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </center>
            </b-col>
            <b-col cols="5">
              <center>
                <span
                  class="text-primary"
                  style="
                    font-weight: bold;
                    margin-right: 8px;
                    margin-top: 100px;
                  "
                >
                  Escaneo por lector
                </span>
              </center>
            </b-col>
          </b-row>
          <b-col
            v-if="!tipoEscaner"
            style="display: flex; justify-content: center"
            cols="12"
          >
            <h2 class="text-primary text-uppercase">
              ¡Escanea el QR de la Nema!
            </h2>
          </b-col>
          <b-row v-if="!tipoEscaner">
            <b-col cols="12">
              <escaner-mobile @datos="escaneoMobileLuminaria" />
            </b-col>
            <b-col
              v-if="datosEscaneadosMobile"
              cols="12"
            >
              <h6 class="text-primary">
                SERIE:
              </h6>
              <b-form-group>
                <b-form-input
                  :value="datosEscaneadosMobile && datosEscaneadosMobile.segundoCodigo"
                  class="text-center"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="datosEscaneadosMobile"
              cols="12"
            >
              <center>
                <b-button
                  variant="primary"
                  class="mt-2 mr-1 align-self-center"
                  @click="extraerCodigos('nema')"
                >
                  <span class="align-middle">Validar Nema</span>
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                  />
                </b-button>
              </center>
            </b-col>
          </b-row>
          <b-row
            v-else
            style="display: flex; justify-content: center"
          >
            <b-row>
              <b-col
                v-if="!textoEscaneado"
                style="display: flex; justify-content: center"
                cols="12"
              >
                <h1 class="text-primary text-uppercase">
                  ¡Escanea el QR de la Nema!
                </h1>
              </b-col>
              <b-col
                v-if="!textoEscaneado"
                style="display: flex; justify-content: center"
                cols="12"
              >
                <b-img
                  :src="require('/public/qr-code-icon.gif')"
                  alt="Escaner QR"
                  style="
                    width: 180px;
                    height: 180px;
                    border-radius: 15px;
                    padding: 5px;
                    box-shadow: 10px 12px 25px rgba(0, 0, 0, 0.5);
                  "
                />
              </b-col>
              <b-col
                v-if="textoEscaneado"
                style="display: flex; justify-content: center"
                cols="12"
              >
                <h1 class="text-primary text-uppercase">
                  ¡Los datos coinciden!
                </h1>
              </b-col>
              <b-col
                v-if="textoEscaneado"
                style="display: flex; justify-content: center"
                cols="12"
              >
                <b-img
                  :src="require('/public/check-green.gif')"
                  alt="Escaner QR"
                  style="
                    width: 180px;
                    height: 180px;
                    border-radius: 15px;
                    padding: 5px;
                    box-shadow: 10px 12px 25px rgba(0, 0, 0, 0.5);
                  "
                />
              </b-col>
            </b-row>
            <b-col cols="12">
              <input
                ref="inputQR"
                v-model="textoEscaneado"
                class="qr-textarea"
                type="text"
                style="position: absolute; top: -9999px"
                @change="extraerCodigos('nema')"
              >
            </b-col>
          </b-row>
        </b-overlay>
      </b-modal>
    </div>
    <div>
      <b-modal
        v-model="modalBusqueda"
        title="Búsqueda manual"
        size="md"
        no-close-on-backdrop
        modal-class="modal-primary"
        hide-footer
      >
        <componente-busqueda-manual
          :origen="busqueda"
          :tipo="'SEGUIMIENTO'"
          :filtro-busqueda="'DESPACHADA'"
          @datos-encontrados="recibirDatos"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BOverlay,
  BCardText,
  BFormTextarea,
  BButton,
  BFormGroup,
  BForm,
  BImg,
  BFormCheckbox,
  BFormInput,
} from 'bootstrap-vue'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import Ripple from 'vue-ripple-directive'
import {
  mensajeError, mensajeInformativo, notificacionError, notificacionInformativa,
} from '@/utils/mensajes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import { saveImagen } from '@/utils/imagenes'
import {
  findLuminariasLab, getLuminariasLab,
  updateCreateLuminariaLab,
} from '@/utils/laboratorio/luminarias'
import { findNemas, getNema, updateCreateNema } from '@/utils/laboratorio/nemas'
import {
  getTrabajosMigracion,
  updateCreateMigracion,
} from '@/utils/migracionLuminarias'
import EscanerMobile from '@/components/escanersQr/escanerMobile.vue'
import { getPoste, updateCreatePoste } from '@/utils/postes'
import { updateCreateEntregaLuminaria } from '@/utils/entregaLuminarias/entregaLuminariasUtils'
import ComponenteBusquedaManual from '@/components/laboratorio/componenteBusquedaManual.vue'

export default {
  components: {
    ComponenteBusquedaManual,
    EscanerMobile,
    BOverlay,
    BRow,
    BCol,
    BCardText,
    BFormTextarea,
    Imagen,
    BForm,
    BImg,
    BButton,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    migracion: {
      type: Object,
      required: true,
    },
    operacion: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
    trabajos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      busqueda: '',
      modalBusqueda: false,
      tipoEscaner: false,
      required,
      loading: false,
      verModalLuminaria: false,
      timeoutId: null,
      verModalNema: false,
      verModalSeguimiento: false,
      comentarios: null,
      textoEscaneado: null,
      fotoInicio: '',
      fotoFin: '',
      usuario: JSON.parse(localStorage.getItem('userData')),
      datosLuminaria: null,
      datosNema: null,
      localization: null,
      datosEscaneadosMobile: null,
    }
  },
  async created() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (this.localization = {
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    extraerWatts(str) {
      const index = str.indexOf('W')
      if (index !== -1) {
        return str.slice(0, index + 1)
      }
      return str
    },
    async recibirDatos(item) {
      if (item.tipoLampara) {
        this.modalBusqueda = false
        this.datosLuminaria = item
        const nombreLuminariaEncontrada = this.extraerWatts(item.tipoLampara.nombre)
        const nombreLuminariaInstalar = this.extraerWatts(this.migracion.levantamientoPunto.lampara.lamparaInstalar.nombre)
        if (nombreLuminariaEncontrada === nombreLuminariaInstalar) {
          this.verModalLuminaria = false
          this.abrirModalLuminaria('nema')
          mensajeInformativo('Coincide', 'Datos de luminaria coinciden!')
        } else {
          mensajeError(
            'La potencia de la luminaria seleccionada no coincide con la luminaria a instalar',
          )
        }
      } else if (!item.tipoLampara) {
        this.datosNema = item
        if (
          String(item.potencia.valor)
          === String(this.datosLuminaria.potencia.valor)
        ) {
          this.verModalNema = false
          this.modalBusqueda = false
          await this.guardarTicket()
        } else {
          mensajeError(
            'La potencia de la nema seleccionada no coincide con la potencia de la luminaria seleccionada anteriormente',
          )
        }
      }
    },
    mostrarModalBusqueda(tipo) {
      this.modalBusqueda = true
      this.busqueda = tipo
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (this.fotoInicio === '' && this.operacion === 'N') {
          mensajeError(
            'Debe ingresar una fotografía para el inicio del ticket!',
          )
          return
        }
        if (success) {
          // eslint-disable-next-line
          this.abrirModalLuminaria("luminaria");
        }
      })
    },
    validationFormFin() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.guardarTicket()
        }
      })
    },
    abrirModalLuminaria(tipo) {
      try {
        if (tipo === 'luminaria') {
          this.verModalLuminaria = true
        } else if (tipo === 'nema') {
          this.verModalNema = true
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.timeoutId = setInterval(() => {
          this.$nextTick(() => {
            if (this.$refs.inputQR) {
              this.$refs.inputQR.focus()
            }
          })
        }, 500)
      }
    },
    extraerCodigos(tipo) {
      try {
        if (!this.tipoEscaner) {
          if (tipo === 'luminaria') {
            this.serieExtraida = this.datosEscaneadosMobile.segundoCodigo
            const filtro = {
              where: {
                serie: this.datosEscaneadosMobile.primerCodigo,
              },
            }
            this.buscarElemento(tipo, filtro).then(() => {
              this.validarLuminaria(tipo)
            })
          } else if (tipo === 'nema') {
            this.serieExtraida = this.datosEscaneadosMobile.segundoCodigo
            const filtro = {
              where: {
                serie: this.datosEscaneadosMobile.segundoCodigo,
              },
            }
            this.buscarElemento(tipo, filtro).then(() => {
              this.validarLuminaria(tipo)
            })
          }
        } else if (tipo === 'luminaria') {
          const removerSegundoCodigo = /S-N[Ññ]\s*([^\s]+)/

          const matchSegundoCodigo = this.textoEscaneado.match(removerSegundoCodigo)

          if (matchSegundoCodigo) {
            // eslint-disable-next-line prefer-destructuring
            this.serieExtraida = matchSegundoCodigo[1]

            const filtro = {
              where: {
                serie: matchSegundoCodigo[1],
              },
            }

            this.buscarElemento(tipo, filtro).then(() => {
              this.validarLuminaria(tipo)
            })
          }
        } else if (tipo === 'nema') {
          const removerSegundoCodigo = /[Ss]-?[Nn][Ññ]?\s*([^\s]+)/i

          const matchSegundoCodigo = this.textoEscaneado.match(removerSegundoCodigo)

          if (matchSegundoCodigo) {
            // eslint-disable-next-line prefer-destructuring
            this.serieExtraida = matchSegundoCodigo[1]

            const filtro = {
              where: {
                serie: matchSegundoCodigo[1],
              },
            }

            this.buscarElemento(tipo, filtro).then(() => {
              this.validarLuminaria(tipo)
            })
          }
        } else {
          mensajeError('Texto del escáner no válido')
          this.textoEscaneado = null
          this.tipoExtraido = null
          this.serieExtraida = null
        }
      } catch (error) {
        console.error(error)
      }
    },
    async validarLuminaria(tipo) {
      try {
        const elementoQR = await this.buscarElemento(tipo)
        if (elementoQR && elementoQR.length > 0) {
          const luminariaEncontrada = elementoQR[0]
          if (luminariaEncontrada.estado === 'DESPACHADA') {
            if (tipo === 'luminaria') {
              this.datosLuminaria = luminariaEncontrada
              const nombreLuminariaEncontrada = this.extraerWatts(luminariaEncontrada.tipoLampara.nombre)
              const nombreLuminariaInstalar = this.extraerWatts(this.migracion.levantamientoPunto.lampara.lamparaInstalar.nombre)
              if (nombreLuminariaEncontrada === nombreLuminariaInstalar) {
                mensajeInformativo('Coincide', 'Datos de luminaria coinciden')
                this.verModalLuminaria = false
                this.abrirModalLuminaria('nema')
              } else {
                mensajeError('Los datos de la luminaria no coinciden')
              }
            } else if (tipo === 'nema') {
              this.datosNema = luminariaEncontrada
              if (
                String(luminariaEncontrada.potencia.valor)
                === String(this.datosLuminaria.potencia.valor)
              ) {
                this.verModalNema = false
                this.textoEscaneado = null
                clearInterval(this.timeoutId)
                await this.guardarTicket()
              } else {
                mensajeError('Los datos de la nema no coinciden')
              }
            } else {
              mensajeError('Los datos no coinciden')
            }
          } else {
            mensajeError('Está no se encuentra en estado DESPACHADA')
          }
        } else {
          mensajeError(
            'No se encontró ninguna ningún dato con la serie y el tipo escaneados',
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
        this.textoEscaneado = null
      }
    },
    async buscarElemento(tipo, filtro = null) {
      try {
        this.loading = true
        let resultadoBusqueda
        if (tipo === 'luminaria') {
          const filter = filtro || {
            where: {
              companyId: this.usuario.company.id,
              serie: this.serieExtraida,
              tipo: this.tipoExtraido,
            },
          }
          resultadoBusqueda = await findLuminariasLab(filter)
        } else if (tipo === 'nema') {
          const filter = filtro || {
            where: {
              companyId: this.usuario.company.id,
              serie: this.serieExtraida,
            },
          }
          resultadoBusqueda = await findNemas(filter)
        }
        return resultadoBusqueda
      } catch (error) {
        this.loading = false
        return null
      }
    },
    async guardarTicket() {
      try {
        this.loading = true

        if (this.fotoInicio === '' && this.operacion === 'N') {
          mensajeError(
            'Debe ingresar una fotografía para el inicio del ticket!',
          )
          return
        }

        if (this.fotoFin === '' && this.operacion === 'F') {
          mensajeError(
            'Debe ingresar una fotografía para finalizar el ticket!',
          )
          return
        }

        const poste = await getPoste(this.migracion.idPuntoIluminacion)

        const encargado = {
          id: this.usuario.id,
          nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
          email: this.usuario.email,
        }

        const migracionBody = {
          id: this.migracion.id,
          seguimiento: this.migracion.seguimiento,
          tracking: this.migracion.tracking,
          trabajos: this.migracion.trabajos,
          estado: this.migracion.estado,
        }

        if (this.migracion.seguimiento && this.migracion.seguimiento.length > 0) {
          if (!this.datosLuminaria) {
            this.datosLuminaria = await getLuminariasLab(this.migracion.seguimiento[0].datosLuminaria.id)
          }
          if (!this.datosNema) {
            this.datosNema = await getNema(this.migracion.seguimiento[0].datosNema.id)
          }
        }

        const luminariaBody = {
          id: this.datosLuminaria.id,
          estado: this.datosLuminaria.estado,
          tracking: this.datosLuminaria.tracking,
        }

        const nemaBody = {
          id: this.datosNema.id,
          estado: this.datosNema.estado,
          tracking: this.datosNema.tracking,
        }

        const urlBucket = `migracion-luminarias/${migracionBody.id}`
        const isMobile = window.screen.width <= 760

        // inicio de ticket
        if (this.operacion === 'N') {
          const imagenInicio = await saveImagen(
            urlBucket,
            this.localization,
            this.fotoInicio,
            'Inicio de Migración',
            this.comentarios,
            encargado,
            isMobile,
          )

          if (!imagenInicio) {
            mensajeError('Error al guardar la imagen, intente nuevamente!')
            return
          }

          imagenInicio.datosLuminaria = {
            id: this.datosLuminaria.id,
            serie: this.datosLuminaria.serie,
            tipo: this.datosLuminaria.tipo,
            potencia: this.datosLuminaria.potencia,
            tipoLampara: this.datosLuminaria.tipoLampara,
          }

          imagenInicio.datosNema = {
            id: this.datosNema.id,
            serie: this.datosNema.serie,
            tipo: this.datosNema.tipo,
            potencia: this.datosNema.potencia,
          }

          migracionBody.seguimiento.push(imagenInicio)

          migracionBody.estado = 'INSTALACION'
          migracionBody.tracking.push({
            estado: 'INSTALACION',
            horaFecha: new Date(),
            usuario: encargado,
          })

          const objTrabjos = await getTrabajosMigracion(this.migracion, this.trabajos)

          if (objTrabjos) {
            migracionBody.trabajos = objTrabjos
          } else {
            mensajeError('Ha ocurrido un error, en la creación de los trabajos')
            notificacionError('Ha ocurrido un error', 'Ha ocurrido un error en la creación de los trabajos')
            return
          }

          // SE PREPARA LA LUMINARIA
          luminariaBody.estado = 'INSTALACION'
          luminariaBody.tracking.push({
            usuario: encargado,
            estado: 'INSTALACION',
            fecha: new Date(),
            posteId: this.migracion.idPuntoIluminacion,
            poste: this.migracion.numeroPoste,
            migracionId: this.migracion.id,
            ticket: this.migracion.ticket,
          })

          // SE PREPARA LA NEMA
          nemaBody.estado = 'INSTALACION'
          nemaBody.tracking.push({
            usuario: encargado,
            estado: 'INSTALACION',
            fecha: new Date(),
            posteId: this.migracion.idPuntoIluminacion,
            poste: this.migracion.numeroPoste,
            migracionId: this.migracion.id,
            ticket: this.migracion.ticket,
          })
        } else if (this.operacion === 'F') {
          const imagenFin = await saveImagen(
            urlBucket,
            this.localization,
            this.fotoFin,
            'Finalización de Migración',
            this.comentarios,
            encargado,
            isMobile,
          )

          if (!imagenFin) {
            mensajeError('Error al guardar la imagen, intente nuevamente!')
            return
          }

          migracionBody.seguimiento.push(imagenFin)
          migracionBody.estado = 'MIGRADA'
          migracionBody.tracking.push({
            estado: 'MIGRADA',
            horaFecha: new Date(),
            usuario: encargado,
          })

          luminariaBody.estado = 'INSTALADA'
          luminariaBody.tracking.push({
            usuario: encargado,
            estado: 'INSTALADA',
            fecha: new Date(),
          })

          nemaBody.estado = 'INSTALADA'
          nemaBody.tracking.push({
            usuario: encargado,
            estado: 'INSTALADA',
            fecha: new Date(),
          })

          if (poste) {
            // 1. dipositivoLampara en punto, en migración es Dispositivo
            poste.dispositivoLampara = this.migracion.levantamientoPunto.lampara.dispositivo.id
            // 2. Modelo Lámpara en punto, pero en la migración es Clase (se pasa id)
            poste.modeloLampara = this.migracion.levantamientoPunto.lampara.clase.id
            // 3. Serie de Lámpara en Punto, pero en migración es Serie de Luminaria
            poste.serieLampara = this.migracion.seguimiento[0].datosLuminaria.serie
            // 5. Luminaria en punto, pero en migración es el nombre del Tipo (Tecnología) (Se pasa el nombre)
            poste.tipoLuminaria = this.migracion.levantamientoPunto.lampara.tipo.nombre
            // 5.1. Tecnología Lampara en punto, pero en migración es Tipo (Tecnología) (Se pasa el id)
            poste.tecnologiaLampara = this.migracion.levantamientoPunto.lampara.tipo.id
            // 6. Potencia en punto, en migración se le pasa la potencia de la luminaria instalada
            poste.potencia = this.migracion.seguimiento[0].datosLuminaria.potencia.valor
            // numeroPoste en punto, migración numeroPoste
            poste.numeroPoste = this.migracion.numeroPoste
          }
        }

        await updateCreateMigracion(migracionBody, 1)

        const response = await updateCreateMigracion(migracionBody, 1)

        if (!response) {
          this.loading = false
          mensajeError('Error actualizando el ticket, intente nuevamente!')
          return
        }
        this.$emit('actualizar-cuerpo-migracion', this.migracion.id)
        await updateCreateNema(nemaBody, 1)
        await updateCreateLuminariaLab(luminariaBody, 1)

        if (this.operacion === 'F') {
          const cambioLampara = migracionBody.trabajos.lampara.trabajos.find(
            trabajo => trabajo.nombre === 'CAMBIO DE LÁMPARA',
          )

          const tercerSeguimiento = { ...cambioLampara.seguimiento[2] }
          tercerSeguimiento.tipo = 'Luminaria Recolectada'
          tercerSeguimiento.fechaHora = new Date()
          tercerSeguimiento.datosPunto = {
            idPunto: this.migracion.levantamientoPunto.puntoInicial.id,
            latitud: this.migracion.levantamientoPunto.puntoInicial.latitud,
            longitud: this.migracion.levantamientoPunto.puntoInicial.longitud,
            serieLampara: this.migracion.levantamientoPunto.puntoInicial.serieLampara,
            potencia: this.migracion.levantamientoPunto.puntoInicial.potencia,
          }

          const bodyEntrega = {
            companyId: this.usuario.company.id,
            puntoIluminacion: this.migracion.numeroPoste,
            tecnicoEncargado: encargado,
            ticket: 0,
            migracionId: this.migracion.id,
            fechaCreacion: new Date(),
            estado: 'RECOLECTADA',
            seguimiento: [tercerSeguimiento],
          }
          await updateCreateEntregaLuminaria(bodyEntrega, 2)
          await updateCreatePoste(poste, 1)
        }
        this.limpiarCampos()
        this.verModalSeguimiento = false
        notificacionInformativa('Sustitución Actualizada', 'Sustitución Actualizada Correctamente!')
        mensajeInformativo('Guardado', 'Seguimiento almacenado correctamente!')
      } catch (error) {
        mensajeError('Error al guardar el ticket, intente nuevamente!')
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    abrirModalSeguimiento() {
      this.verModalSeguimiento = true
    },
    todosSeguimientosCompletos() {
      if (this.operacion === 'F' && this.migracion.trabajos) {
        const seguimientosCompletos = this.migracion.trabajos.brazo.trabajos.every(
          trabajo => trabajo.seguimiento && trabajo.seguimiento.length === 2,
        )
          && this.migracion.trabajos.poste.trabajos.every(
            trabajo => trabajo.seguimiento && trabajo.seguimiento.length === 2,
          )
          && this.migracion.trabajos.otrosTrabajos.trabajos.every(
            trabajo => trabajo.seguimiento && trabajo.seguimiento.length === 2,
          )
          && this.migracion.trabajos.conexion.trabajos.every(
            trabajo => trabajo.seguimiento && trabajo.seguimiento.length === 2,
          )

        const seguimientosLampara = this.migracion.trabajos.lampara.trabajos
        const seguimientosLamparaCompletos = seguimientosLampara.every(
          trabajo => trabajo.seguimiento && trabajo.seguimiento.length >= 2,
        )
          && seguimientosLampara.some(
            trabajo => trabajo.nombre === 'CAMBIO DE LÁMPARA'
              && trabajo.seguimiento
              && trabajo.seguimiento.length >= 3,
          )

        return seguimientosCompletos && seguimientosLamparaCompletos
      }
      return true
    },
    escaneoMobileLuminaria(datosEscaneados) {
      this.datosEscaneadosMobile = datosEscaneados
    },
    limpiarCampos() {
      this.comentarios = null
      this.fotoInicio = ''
      this.fotoFin = ''
      clearInterval(this.timeoutId)
    },
    limpiarIntervalo() {
      clearInterval(this.timeoutId)
    },
    imagenInicio(file) {
      this.fotoInicio = file
    },
    imagenFin(file) {
      this.fotoFin = file
    },
  },
}
</script>
