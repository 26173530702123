<template>
  <div>
    <b-overlay :show="loading">
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-row>
              <b-col
                cols="10"
              >
                <b-form-input
                  v-model="busqueda"
                  placeholder="Serie, Tipo..."
                  class="qr-textarea"
                  @keyup.enter="buscarItem()"
                />
              </b-col>
              <b-col
                cols="2"
              >
                <b-button
                  variant="primary"
                  @click="buscarItem"
                >
                  <feather-icon :icon="'SearchIcon'" />
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card
            v-for="(item, index) in datosEncontrados"
            :key="index"
          >
            <b-row>
              <b-col cols="12">
                <b-alert
                  :variant="tipo === 'SEGUIMIENTO' ? 'primary' : 'warning'"
                  show
                >
                  <div class="alert-body">
                    <center>
                      <span>
                        <strong>{{ estadoSeguimiento(item.estado) }}</strong>
                      </span>
                    </center>
                  </div>
                </b-alert>
              </b-col>
              <b-col
                v-if="item.tipoLampara"
                cols="12"
              >
                <b-form-group>
                  <h5>Tipo Lámpara</h5>
                  <b-form-input
                    class="text-center"
                    :value="item.tipoLampara.nombre"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="origen === 'NEMAS' || origen === 'NEMAS_MODULO'"
                cols="12"
              >
                <b-form-group>
                  <h5>Tipo</h5>
                  <b-form-input
                    class="text-center"
                    :value="item.tipo.nombre"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <h5>Serie</h5>
                  <b-form-input
                    class="text-center"
                    :value="item.serie"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="origen === 'NEMAS' || origen === 'NEMAS_MODULO'"
                cols="6"
              >
                <b-form-group>
                  <h5>Potencia</h5>
                  <b-form-input
                    class="text-center"
                    :value="item.potencia.valor"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="origen === 'LUMINARIAS' || origen === 'LUMINARIAS_MODULO'"
                cols="6"
              >
                <b-form-group>
                  <h5>Tipo</h5>
                  <b-form-input
                    class="text-center"
                    :value="item.tipo"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <h5>Fecha Creación</h5>
                  <b-form-input
                    class="text-center"
                    :value="formatDate(item.fechaCreacion, '/')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="tipo === 'DESPACHO'"
                cols="12"
              >
                <b-button
                  block
                  variant="primary"
                  @click="agregarItem(item)"
                >
                  Agregar a la lista de despacho
                  <feather-icon :icon="'PlusIcon'" />
                </b-button>
              </b-col>
              <b-col
                v-if="tipo === 'SEGUIMIENTO'"
                cols="12"
              >
                <b-button
                  block
                  variant="primary"
                  @click="seleccionarSeguimiento(item)"
                >
                  Seleccionar y pasar a validar
                  <feather-icon :icon="'PlusIcon'" />
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BButton, BFormInput, BAlert, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import { mensajeError, notificacionError } from '@/utils/mensajes'
import { findLuminariasLab, getUtilsLaboratorio } from '@/utils/laboratorio/luminarias'
import { findNemas } from '@/utils/laboratorio/nemas'
import { formatDate } from '@/utils/fechas'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BButton,
    BOverlay,
    BFormInput,
    BFormGroup,
  },
  props: {
    origen: {
      type: String,
      required: true,
    },
    tipo: {
      type: String,
      required: true,
    },
    filtroBusqueda: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      busqueda: '',
      loading: false,
      datosEncontrados: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    this.estados = getUtilsLaboratorio(3)
  },
  methods: {
    formatDate,
    agregarItem(item) {
      this.$emit('item-recibido', item)
    },
    seleccionarSeguimiento(item) {
      this.$emit('datos-encontrados', item)
    },
    async buscarItem() {
      this.loading = true
      try {
        if (this.busqueda.trim() === '') {
          mensajeError('Ingrese la Serie o el Tipo!')
          return
        }
        const filter = {
          where: {
            companyId: this.usuario.company.id,
            and: [],
          },
        }
        const buscarPorEstado = {
          or: [
            { estado: this.filtroBusqueda },
          ],
        }
        filter.where.and.push(buscarPorEstado)
        if (this.busqueda.trim() !== '') {
          const buscarLuminariaNema = {
            or: [
              { serie: { like: this.busqueda.trim(), options: 'i' } },
              { tipo: { like: this.busqueda.trim(), options: 'i' } },
            ],
          }
          filter.where.and.push(buscarLuminariaNema)
        }
        filter.fields = {
          id: true,
          companyId: true,
          estado: true,
          serie: true,
          tipo: true,
          potencia: true,
          tipoLampara: true,
          tracking: true,
          seguimiento: true,
          fechaCreacion: true,
        }
        if (this.origen === 'LUMINARIAS' || this.origen === 'LUMINARIAS_MODULO') {
          this.datosEncontrados = await findLuminariasLab(filter)
        } else if (this.origen === 'NEMAS' || this.origen === 'NEMAS_MODULO') {
          this.datosEncontrados = await findNemas(filter)
        }
        if (this.datosEncontrados.length === 0) {
          mensajeError('No hay resultados para esta búsqueda.')
        }
      } catch (error) {
        console.error(error)
        notificacionError('Error', 'Ha ocurrido un error en la búsqueda')
      } finally {
        this.loading = false
      }
    },
    estadoSeguimiento(estado) {
      return this.estados[estado]?.nombre || estado
    },
  },
}
</script>
